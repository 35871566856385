import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthenticatedRoutesWrapper } from 'components';
import { routes, routeNames } from 'routes';
import { Footer } from './Footer';
import { Navbar } from './Navbar';
import { FormatAmount } from 'components/sdkDappComponents';
import { Link } from 'react-router-dom';
import { request } from 'graphql-request';
import { graphqlUrl } from 'config';
import { Query } from 'types';
import { adminAddresses, utxIdentifier, utxDecimals } from 'config';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import Decimal from 'decimal.js';
import { NavItem, Nav } from 'react-bootstrap';
import { logout } from 'helpers';
import {
    useGetIsLoggedIn,
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import BigNumber from 'bignumber.js';

export const Layout = ({ children }: { children: React.ReactNode }) => {
    const isLoggedIn = useGetIsLoggedIn();
    const { success } = useGetActiveTransactionsStatus();
    const { address, username } = useGetAccount();
    const {
        network: { apiAddress },
        chainID
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const navbarExpand = 'md';

    const [tokenUserBalance, setTokenUserBalance] =
        useState<BigNumber | null>();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();

    useEffect(() => {
        if (address) {
            apiNetworkProvider
                .getAccountTokenBalance(address, utxIdentifier)
                .then((balance) => setTokenUserBalance(balance));

            request<Query>(
                graphqlUrl,
                `
                query {
                    ev {
                        tokenPrice
                    }
                }
                `
            )
                .then(({ ev }) => {
                    if (!ev) return;
                    if (!ev.tokenPrice) return;
                    setTokenPrice(new Decimal(ev.tokenPrice));
                })
                .catch((err: any) => {
                    console.error(err);
                });
        }
    }, [address, success]);

    const handleLogout = () => {
        logout(`${window.location.origin}/unlock`);
    };

    const { search } = useLocation();

    return (
        <div className='ev-wrap container-fluid z-index-100'>
            <div className='d-flex container-fluid flex-row w-100'>
                <div className='layout-lateral ev-lateral-menu py-3'>
                    <div className='ev-lateral-menu-content'>
                        <Navbar />
                    </div>
                </div>
                <div className='layout-container w-100 w-md-90  d-flex flex-column flex-fill wrapper py-5'>
                    <div className='d-flex flex-column flex-md-row text-center align-items-center mx-1 mx-md-5'>
                        {isLoggedIn && (
                            <>
                                <NavItem className='text-light text-center text-md-left'>
                                    Welcome,&nbsp;
                                    {username
                                        ? username.replace('.elrond', '')
                                        : shortenAddress(address, 5)}
                                    !
                                </NavItem>
                                <NavItem
                                    className={`m-0 mx-md-auto text-light text-center`}
                                >
                                    {tokenUserBalance && (
                                        <>
                                            <span>
                                                Balance:&nbsp;
                                                <FormatAmount
                                                    value={tokenUserBalance.toString(
                                                        10
                                                    )}
                                                    token={
                                                        '$' +
                                                        utxIdentifier.split(
                                                            '-'
                                                        )[0]
                                                    }
                                                    digits={2}
                                                    decimals={utxDecimals}
                                                />
                                                {tokenPrice && (
                                                    <span className='mx-2 equivalent-usdc'>
                                                        ~&nbsp;
                                                        {new Decimal(
                                                            tokenUserBalance.toString(
                                                                10
                                                            )
                                                        )
                                                            .div(10 ** 18)
                                                            .mul(tokenPrice)
                                                            .toSignificantDigits(
                                                                3
                                                            )
                                                            .toString()}
                                                        $
                                                    </span>
                                                )}
                                            </span>
                                        </>
                                    )}
                                </NavItem>
                            </>
                        )}

                        <Nav className='mt-2 mt-md-0 ml-md-auto align-items-center'>
                            {isLoggedIn && (
                                <>
                                    {adminAddresses.includes(address) && (
                                        <NavItem>
                                            <Link
                                                to={routeNames.adminSettings}
                                                className={
                                                    'btn btn-primary mb-2 mb-' +
                                                    navbarExpand +
                                                    '-0 mr-0 mr-' +
                                                    navbarExpand +
                                                    '-2 btn-custom'
                                                }
                                            >
                                                Admin Settings
                                            </Link>
                                        </NavItem>
                                    )}
                                    <NavItem>
                                        <button
                                            className='btn btn-primary btn-custom'
                                            onClick={handleLogout}
                                        >
                                            Logout
                                        </button>
                                    </NavItem>
                                </>
                            )}
                        </Nav>
                    </div>
                    <AuthenticatedRoutesWrapper
                        routes={routes}
                        unlockRoute={`${routeNames.unlock}${search}`}
                    >
                        {children}
                    </AuthenticatedRoutesWrapper>
                    <Footer />
                </div>
            </div>
            <div className='bg-bubble-1'></div>
            <div className='bg-bubble-2'></div>
        </div>
    );
};
export const shortenAddress = (address: string, length: number = 8) => {
    return (
        address.substring(0, length) +
        '...' +
        address.substring(address.length - length, address.length)
    );
};
