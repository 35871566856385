import { NonFungibleToken } from 'types';

type NftVisualizerProps = {
    nft: NonFungibleToken;
    contract: string;
    changeCallback: (checked: boolean) => void;
};

export const NftVisualizer = ({
    nft,
    contract,
    changeCallback
}: NftVisualizerProps) => {
    return (
        <label htmlFor={'check-' + nft.identifier + '-' + contract}>
            <div className='card card-nft'>
                <div className='card-header d-flex flex-column'>
                    <div className='hover-img'>
                        <img
                            src={nft.media[0].thumbnailUrl}
                            className='img-fluid img-nft'
                            alt={nft.name}
                        />
                    </div>
                    <h6 className='m-0 font-weight-bold'>{nft.name}</h6>
                </div>
                <div className='nft-checkbox-container'>
                    <input
                        type='checkbox'
                        className='form-control form-check-input nft-checkbox'
                        id={'check-' + nft.identifier + '-' + contract}
                        checked={nft._checked}
                        onChange={(e) => changeCallback(e.target.checked)}
                    />
                </div>
            </div>
        </label>
    );
};
