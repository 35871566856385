import { useEffect, useMemo, useState } from 'react';
import { request } from 'graphql-request';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlNftStaking } from 'types';
import Decimal from 'decimal.js';
import { NftStakingPoolComponent } from 'components/NftStakingPoolComponent';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';

export const RewardsHistory = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();

    const [transactions, setTransactions] = useState<any[]>([]);
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                  }
                  tokenStakingPools {
                    _address
                    stakingToken
                    rewardToken
                    rewardsAmount
                  }   
                }
              }
            `
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                if (!ev.tokenStakingPools) return;

                const url = `https://api.multiversx.com/accounts/${address}/transactions?receiver=${[
                    ...ev.nftStakingPools.map((pool: any) => pool._address),
                    ...ev.tokenStakingPools.map((pool: any) => pool._address)
                ].join(
                    ','
                )}&function=claim_rewards,claimRewards&status=success&size=50&withOperations=true`;

                fetch(url)
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res);
                        setTransactions(res);
                    });
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    const rewards = useMemo(() => {
        return transactions
            .map(
                (transaction) =>
                    transaction.operations
                        ?.filter(
                            (operation: any) => operation.action === 'transfer'
                        )
                        .map((operation: any) => {
                            return {
                                amount: BigInt(operation.value),
                                token: operation.ticker ?? operation.type
                            };
                        })
            )
            .flat()
            .filter((operation: any) => operation !== undefined)
            .reduce((acc: any, { amount, token }) => {
                if (!acc[token]) {
                    acc[token] = BigInt(0);
                }
                acc[token] = acc[token] + amount;
                return acc;
            }, {});
    }, [transactions]);

    const addressToName = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v'
        ) {
            return 'Infinity Staking Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh'
        ) {
            return 'OAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m'
        ) {
            return 'EVOAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze'
        ) {
            return 'Duo Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
        ) {
            return 'Trio Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p'
        ) {
            return 'E.V Membership Cards Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz'
        ) {
            return 'OAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h'
        ) {
            return 'EVOAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
        ) {
            return 'Legendary Bald';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5'
        ) {
            return 'Graffiti Crown OAG';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
        ) {
            return 'Graffiti Crown EVOAG';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqqr8y7v7u8z4r5ral9ytcr9dfs6g9hmhhdn3qvk485n'
        ) {
            return 'UTX Normal Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqpc2hzp55zcekadg5q0t930zvqhp2gx22dn3qjjxn7h'
        ) {
            return 'UTX High Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq6sqyxxr9mjc3w90czqmh4dwdkfg9yz39dn3qqkylyk'
        ) {
            return 'LP Farm';
        }
        return address;
    };

    return (
        <div className='rewards-history container '>
            <div className='container mt-5'>
                <h1 className='text-center mb-5 text-custom ev-text'>
                    Rewards History (last 50 claims)
                </h1>

                <div className='d-flex align-center justify-content-center'>
                    <div className='w-100 card card-dashboard text-center text-white pt-5'>
                        <h3 className='text-center text-custom ev-text'>
                            Total Claimed Rewards
                        </h3>
                        <FormatAmount value={rewards.UNITYX} token={'UNITYX'} />
                        <FormatAmount value={rewards.egld} />

                        <table className='table table-hover mt-4'>
                            <thead>
                                <tr>
                                    <th scope='col'>Amount</th>
                                    <th scope='col'>Date</th>
                                    <th scope='col'>Pool</th>
                                    <th scope='col'>Tx</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions.map((transaction) => (
                                    <tr key={transaction.hash}>
                                        <td className='td-amount'>
                                            {transaction.operations
                                                ?.filter(
                                                    (operation: any) =>
                                                        operation.action ===
                                                        'transfer'
                                                )
                                                .map((operation: any) => {
                                                    return (
                                                        <div
                                                            key={operation.hash}
                                                        >
                                                            <FormatAmount
                                                                value={
                                                                    operation.value
                                                                }
                                                                token={(
                                                                    operation.ticker ??
                                                                    operation.type
                                                                ).toUpperCase()}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                        </td>
                                        <td>
                                            {new Date(
                                                transaction.timestamp * 1000
                                            ).toLocaleDateString()}
                                        </td>
                                        <td>
                                            {addressToName(
                                                transaction.receiver
                                            )}
                                        </td>
                                        <td className='td-explorer'>
                                            <a
                                                href={`https://explorer.multiversx.com/transactions/${transaction.txHash}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                🌐
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
