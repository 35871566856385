import React, { useState } from 'react';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { string2hex } from 'helpers';
import { InternalToken } from 'types';
import {
    TokenPayment,
    Address,
    TransferTransactionsFactory,
    GasEstimator
} from '@multiversx/sdk-core';
import { useGetAccount } from 'hooks';
import BigNumber from 'bignumber.js';

export const DepositRewards = ({
    scAddress,
    token,
    endpoint = 'depositRewards'
}: {
    scAddress: string;
    token?: InternalToken;
    endpoint?: string;
}) => {
    const [depositRewards, setDepositRewards] = useState('0');
    const { address } = useGetAccount();

    const depositRewardsToContract = async () => {
        await refreshAccount();

        if (token) {
            const payload =
                new TransferTransactionsFactory(new GasEstimator())
                    .createESDTTransfer({
                        tokenTransfer: TokenPayment.fungibleFromAmount(
                            token.identifier,
                            new BigNumber(depositRewards),
                            token.decimals
                        ),
                        receiver: new Address(scAddress),
                        sender: new Address(address),
                        chainID: '1'
                    })
                    .getData()
                    .toString() +
                '@' +
                string2hex(endpoint);

            const { sessionId } = await sendTransactions({
                transactions: {
                    value: 0,
                    data: payload,
                    receiver: scAddress,
                    gasLimit: 10_000_000
                },
                transactionsDisplayInfo: {
                    processingMessage: 'Depositing rewards...',
                    errorMessage: 'An error has occured during deposit',
                    successMessage: 'Rewards deposited successfully'
                }
            });
        } else {
            const { sessionId } = await sendTransactions({
                transactions: {
                    value: new BigNumber(depositRewards)
                        .multipliedBy(10 ** 18)
                        .toString(),
                    data: 'depositRewards',
                    receiver: scAddress,
                    gasLimit: 10_000_000
                },
                transactionsDisplayInfo: {
                    processingMessage: 'Depositing rewards...',
                    errorMessage: 'An error has occured during deposit',
                    successMessage: 'Rewards deposited successfully'
                }
            });
        }
    };

    return (
        <div>
            <input
                type='text'
                className='form-control'
                placeholder='Amount'
                value={depositRewards}
                onChange={(e) => setDepositRewards(e.target.value)}
            />
            <button
                className='btn btn-primary mt-2'
                onClick={depositRewardsToContract}
            >
                Deposit rewards
            </button>
        </div>
    );
};
