import { useEffect, useState, useMemo } from 'react';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import {
    TokenPayment,
    TransferTransactionsFactory,
    GasEstimator,
    Address
} from '@multiversx/sdk-core';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { mBoxIdentifier, mBoxScAddress } from 'config';
import { string2hex, hex2string } from 'helpers';
import { NonFungibleToken } from 'types';
import { BigNumber } from 'bignumber.js';
import ContainerGold from 'assets/img/container-gold.png';
import ContainerSilver from 'assets/img/container-silver.png';
import ContainerBronze from 'assets/img/container-bronze.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

enum Step {
    start,
    opening,
    opened
}

enum PrizeType {
    Nft = 1,
    PartnerNft = 2,
    Egld = 3,
    RomaTicket = 4,
    Utx = 5,
    Token = 6,
    Voucher = 7
}

type Prize = {
    id: number;
    type: PrizeType;
    token: string;
    amount: BigNumber;
    nonce: number;
};

export const MysteryBox = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const [step, setStep] = useState<Step>(Step.start);
    const [openingType, setOpeningType] = useState<
        'Bronze' | 'Silver' | 'Gold'
    >('Bronze');
    const [openingMultiple, setOpeningMultiple] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<string>('');

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();
    const transactionStatus = useTrackTransactionStatus({ transactionId });

    const [mysteryBoxes, setMysteryBoxes] = useState<NonFungibleToken[]>([]);
    const [prizes, setPrizes] = useState<Prize[]>([]);

    const startOpenBox = async (type: 'Bronze' | 'Silver' | 'Gold') => {
        const nftToOpen = mysteryBoxes.find((mb: any) => mb._mbType === type);

        if (!nftToOpen) {
            return;
        }

        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createESDTNFTTransfer({
                    tokenTransfer: TokenPayment.nonFungible(
                        nftToOpen.collection,
                        nftToOpen.nonce
                    ),
                    destination: new Address(mBoxScAddress),
                    sender: new Address(address),
                    chainID: '1'
                })
                .getData()
                .toString() +
            '@' +
            string2hex('openMysteryBox');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 12_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Opening Container...',
                errorMessage: 'An error has occured during opening',
                successMessage: 'Container opened successfully!'
            }
        });

        setOpeningType(type);
        setOpeningMultiple(false);
        setTransactionId(sessionId);
    };

    const startOpenMultipleBox = async (type: 'Bronze' | 'Silver' | 'Gold') => {
        const nftsOfType = mysteryBoxes.filter(
            (mb: any) => mb._mbType === type
        );
        const nftsToOpen = nftsOfType.slice(0, 10);

        if (nftsToOpen.length === 0) {
            return;
        }

        const payload =
            new TransferTransactionsFactory(new GasEstimator())
                .createMultiESDTNFTTransfer({
                    tokenTransfers: nftsToOpen.map((nft) =>
                        TokenPayment.nonFungible(nft.collection, nft.nonce)
                    ),
                    destination: new Address(mBoxScAddress),
                    sender: new Address(address),
                    chainID: '1'
                })
                .getData()
                .toString() +
            '@' +
            string2hex('openMultiMysteryBox');

        await refreshAccount();

        const { sessionId } = await sendTransactions({
            transactions: {
                value: 0,
                data: payload,
                receiver: address,
                gasLimit: 60_000_000
            },
            transactionsDisplayInfo: {
                processingMessage: 'Opening Containers...',
                errorMessage: 'An error has occured during opening',
                successMessage: 'Containers opened successfully!'
            }
        });

        setOpeningType(type);
        setOpeningMultiple(true);
        setTransactionId(sessionId);
    };
    const [animateImageOut, setAnimateImageOut] = useState(false);
    useEffect(() => {
        if (step !== Step.opening) {
            setAnimateImageOut(true);
            setTimeout(() => {
                setAnimateImageOut(false); // Reset the state after the animation is completed
            }, 1000); // The duration of the zoomOut animation
        }
    }, [step]);

    useEffect(() => {
        if (transactionStatus.isPending) {
            setStep(Step.opening);
        }
        if (transactionStatus.isSuccessful) {
            const txHash = transactionStatus?.transactions?.[0]?.hash;
            fetchNfts();
            if (!txHash) {
                return;
            }

            apiNetworkProvider
                .getTransaction(txHash)
                .then((transaction) => {
                    const scResult = transaction.contractResults.items.find(
                        (item) => item.data.startsWith('@6f6b@')
                    );

                    if (!scResult) {
                        return;
                    }

                    if (openingMultiple) {
                        const prizesData = scResult.data.split('@').slice(2);
                        const prizes = [];

                        for (let i = 0; i < prizesData.length; i += 5) {
                            let [
                                prizeId,
                                prizeType,
                                prizeToken,
                                prizeAmount,
                                prizeNonce
                            ] = prizesData.slice(i, i + 5);

                            prizes.push({
                                id: parseInt(prizeId, 16),
                                type: parseInt(prizeType, 16) as PrizeType,
                                token: hex2string(prizeToken),
                                amount: new BigNumber(prizeAmount, 16),
                                nonce: prizeNonce ? parseInt(prizeNonce, 16) : 0
                            });
                        }

                        setPrizes(prizes);
                    } else {
                        let [
                            _,
                            _ok,
                            prizeId,
                            prizeType,
                            prizeToken,
                            prizeAmount,
                            prizeNonce
                        ] = scResult.data.split('@');

                        setPrizes([
                            {
                                id: parseInt(prizeId, 16),
                                type: parseInt(prizeType, 16) as PrizeType,
                                token: hex2string(prizeToken),
                                amount: new BigNumber(prizeAmount, 16),
                                nonce: prizeNonce ? parseInt(prizeNonce, 16) : 0
                            }
                        ]);
                    }

                    setStep(Step.opened);
                })
                .catch((error) => console.error(error));
        }
    }, [transactionStatus.status]);

    const fetchNfts = () => {
        apiNetworkProvider
            .getAccountNftsFromCollection(address, mBoxIdentifier)
            .then((nfts) => {
                setMysteryBoxes(
                    nfts.map((nft) => ({
                        ...nft,
                        _mbType:
                            (nft as any).metadata?.attributes?.find(
                                (attr: any) => attr.trait_type === 'Edition'
                            )?.value ?? 'Bronze'
                    }))
                );
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        fetchNfts();
    }, []);

    const videoUrl = useMemo(() => {
        if (prizes.length === 0) {
            return '';
        }

        let video = 'opening-video-container/';
        video += openingType + '/';
        video += prizes[0].type;

        /*
        if (prizes[0].type === PrizeType.Egld) {
            video += '_' + prizes[0].amount.toString(10);
        } else if (prizes[0].type === PrizeType.Discount) {
            video += '_' + prizes[0].amount.toString(10);
        }
        */

        video += '.mp4';
        return video;
    }, [prizes]);

    return (
        <div>
            <div className='container mt-5'>
                <div className='text-center'>
                    {step === Step.start && (
                        <>
                            <h1 className='mt-4 mb-4 pb-4 '>
                                {mysteryBoxes.length === 0 ? (
                                    "You don't have any Container to open"
                                ) : (
                                    <>
                                        You have{' '}
                                        <span className='text-custom'>
                                            {mysteryBoxes.length} Container
                                            {mysteryBoxes.length > 1
                                                ? 's'
                                                : ''}{' '}
                                        </span>
                                        to open
                                    </>
                                )}
                            </h1>

                            <div className='row justify-content-evenly gap-30'>
                                {['Silver', 'Gold', 'Bronze'].map((type) => (
                                    <div className='col-md card card-dashboard'>
                                        <h2 className='mb-4 mt-3'>
                                            {
                                                mysteryBoxes.filter(
                                                    (mb: any) =>
                                                        mb._mbType === type
                                                ).length
                                            }
                                            &nbsp;{type}
                                        </h2>

                                        <img
                                            src={
                                                type === 'Gold'
                                                    ? ContainerGold
                                                    : type === 'Silver'
                                                      ? ContainerSilver
                                                      : ContainerBronze
                                            }
                                            alt={'mb-' + type}
                                            className='mb-5 w-100'
                                        />

                                        <div>
                                            <button
                                                className='btn btn-primary btn-lg w-75 mb-3 mx-auto'
                                                disabled={
                                                    mysteryBoxes.filter(
                                                        (mb: any) =>
                                                            mb._mbType === type
                                                    ).length === 0
                                                }
                                                onClick={() =>
                                                    startOpenBox(
                                                        type as
                                                            | 'Bronze'
                                                            | 'Silver'
                                                            | 'Gold'
                                                    )
                                                }
                                            >
                                                Open 1
                                            </button>

                                            {mysteryBoxes.filter(
                                                (mb: any) => mb._mbType === type
                                            ).length >= 10 && (
                                                <button
                                                    className='btn btn-primary btn-lg w-75 mb-3 mx-auto'
                                                    disabled={
                                                        mysteryBoxes.filter(
                                                            (mb: any) =>
                                                                mb._mbType ===
                                                                type
                                                        ).length === 0
                                                    }
                                                    onClick={() =>
                                                        startOpenMultipleBox(
                                                            type as
                                                                | 'Bronze'
                                                                | 'Silver'
                                                                | 'Gold'
                                                        )
                                                    }
                                                >
                                                    Open 10
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {step === Step.opening && (
                        <>
                            <h1 className='mb-4'>
                                Opening {openingMultiple ? 'ten' : 'one'}&nbsp;
                                {openingType}
                                &nbsp;Container. Please wait!{' '}
                                <span className='waiting-text'></span>
                            </h1>

                            <img
                                src={
                                    openingType === 'Gold'
                                        ? ContainerGold
                                        : openingType === 'Silver'
                                          ? ContainerSilver
                                          : ContainerBronze
                                }
                                alt='opening-mb'
                                className={`mb-5 w-75 mt-5 ${
                                    animateImageOut ? 'zoom-out-animation' : ''
                                }`}
                                style={{
                                    maxWidth: '600px'
                                }}
                            />
                        </>
                    )}

                    {step === Step.opened && (
                        <>
                            <h1 className='mb-3'>
                                Your container
                                {openingMultiple ? ' has' : 's have'} been
                                opened!
                            </h1>

                            {!openingMultiple && (
                                <video
                                    id='vid'
                                    playsInline
                                    autoPlay
                                    muted
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        maxWidth: '600px'
                                    }}
                                    className='mb-3'
                                >
                                    <source src={videoUrl} type='video/mp4' />
                                </video>
                            )}
                        </>
                    )}
                </div>
            </div>

            {step === Step.opened && (
                <div className='container text-center'>
                    <div className='row justify-content-evenly my-5 card-container'>
                        <TransitionGroup component={null}>
                            {prizes.map((prize, i) => (
                                <CSSTransition
                                    key={i}
                                    timeout={500}
                                    classNames='card-anim'
                                >
                                    <div className='col-custom-5 col-md-4 col-sm-6 col-12 mb-3 d-flex justify-content-center '>
                                        <div className='card card-dashboard mb-2 card-prize flex-fill'>
                                            <div className='card-body'>
                                                <h3 className='card-title'>
                                                    Prize
                                                    {openingMultiple &&
                                                        ' #' + (i + 1)}
                                                </h3>
                                                <p className='card-text'>
                                                    <b>
                                                        {prize.type ===
                                                            PrizeType.Nft &&
                                                            'E.V Ecosystem NFT'}
                                                        {prize.type ===
                                                            PrizeType.PartnerNft &&
                                                            'Partner NFT (' +
                                                                prize.token +
                                                                ')'}
                                                        {prize.type ===
                                                            PrizeType.Egld &&
                                                            prize?.amount
                                                                .dividedBy(1e18)
                                                                .toString() +
                                                                ' eGLD'}
                                                        {prize.type ===
                                                            PrizeType.RomaTicket &&
                                                            'Roma ticket'}
                                                        {prize.type ===
                                                            PrizeType.Utx &&
                                                            prize?.amount
                                                                .dividedBy(1e18)
                                                                .toString() +
                                                                ' UTX'}
                                                        {prize.type ===
                                                            PrizeType.Token &&
                                                            prize?.amount
                                                                .dividedBy(
                                                                    prize?.token ===
                                                                        'QWT-46ac01'
                                                                        ? 1e6
                                                                        : 1e18
                                                                )
                                                                .toString() +
                                                                ' ' +
                                                                prize?.token}
                                                        {prize.type ===
                                                            PrizeType.Voucher &&
                                                            'Voucher'}
                                                    </b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </CSSTransition>
                            ))}
                        </TransitionGroup>
                    </div>

                    <button
                        className='btn btn-primary btn-lg btn-padding'
                        onClick={() => setStep(Step.start)}
                    >
                        Open another one!
                    </button>
                </div>
            )}
        </div>
    );
};
