import React, { useEffect, useState } from 'react';
import { DepositRewards } from 'components/Admin/DepositRewards';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { request } from 'graphql-request';
import { Query, gqlNftStaking, gqlTokenStaking } from 'types';
import { graphqlUrl, utxToken } from 'config';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';

export const AdminSettings = () => {
    const [nftStakingPools, setNftStakingPools] = useState<
        gqlNftStaking[] | undefined
    >();
    const [tokenStakingPools, setTokenStakingPools] = useState<
        gqlTokenStaking[] | undefined
    >();

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                    nStakedPositions
                    nUnstakingPositions
                    stakedAddresses {
                        address
                        staked {
                            nonce
                        }
                    }
                    pendingRewards {
                        tokenId
                        amount
                    }
                  }
                  tokenStakingPools {
                    _address
                    stakingToken
                    rewardToken
                    rewardsAmount
                    stakedAddresses
                  }
                }
              }
            `
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                if (!ev.nftStakingPools) return;

                setNftStakingPools(ev.nftStakingPools);
                setTokenStakingPools(ev.tokenStakingPools);
            })
            .catch((err: any) => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const addressToName = (address: string) => {
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqd7f32re7pj0xqha8zru8kgwsqepvhn8kdn3qxej29v'
        ) {
            return 'Infinity Staking Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh'
        ) {
            return 'OAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m'
        ) {
            return 'EVOAG Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze'
        ) {
            return 'Duo Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
        ) {
            return 'Trio Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqv7c5juqtewtcc9krjnt0jhmudgas3hzqdn3qtny67p'
        ) {
            return 'E.V Membership Cards Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz'
        ) {
            return 'OAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h'
        ) {
            return 'EVOAG Prime Pool';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgq0g3jdnd2jlw2nh0zxs4ghedr3qmwnntkdn3qha9myl'
        ) {
            return 'Legendary Bald';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5'
        ) {
            return 'Graffiti Crown OAG';
        }
        if (
            address ===
            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
        ) {
            return 'Graffiti Crown EVOAG';
        }
        return address;
    };

    return (
        <div>
            <div className='container'>
                {nftStakingPools?.map((pool) => (
                    <div className='card p-4 mt-4'>
                        <h1>{addressToName(pool._address!)}</h1>
                        <h3>
                            {pool.nStakedPositions} Staked NFTs
                            <br />
                            {pool.nUnstakingPositions} Unstaked NFTs
                            <br />
                            {pool.stakedAddresses!.length} Staked Addresses
                            <br />
                        </h3>
                        <DownloadButton
                            textOutput={pool
                                .stakedAddresses!.map((user) => {
                                    return `${user.address};${
                                        user.staked!.length
                                    }`;
                                })
                                .join('\n')}
                            fileName={
                                (
                                    pool.stakingTokens ?? [pool.stakingToken]
                                ).join('&') + '_stakers'
                            }
                        />

                        {pool.rewardTokens!.map((rewardToken) => (
                            <>
                                <hr />
                                <h3>
                                    Deposit {rewardToken.split('-')[0]} Rewards
                                    (
                                    <FormatAmount
                                        value={pool
                                            .pendingRewards!.find(
                                                (pendingReward) =>
                                                    pendingReward.tokenId ===
                                                    rewardToken
                                            )!
                                            .amount!.toString(10)}
                                        token={rewardToken.split('-')[0]}
                                        digits={5}
                                        decimals={18}
                                    />
                                    &nbsp;already deposited - not distributed)
                                </h3>

                                <DepositRewards
                                    scAddress={pool._address!}
                                    token={
                                        rewardToken === utxToken.identifier
                                            ? utxToken
                                            : undefined
                                    }
                                />
                            </>
                        ))}
                    </div>
                ))}
                {tokenStakingPools?.map((pool) => (
                    <div className='card p-4 mt-4'>
                        <h1>{pool.stakingToken} Pool</h1>
                        <h3>
                            {pool.stakedAddresses?.length} Staked Addresses
                            <br />
                            {/*
							{pool.nStakedPositions} Staked NFTs
							<br />
							{pool.nUnstakingPositions} Unstaked NFTs
							<br />
                            */}
                        </h3>
                        {/*
						<DownloadButton
							textOutput={pool
								.stakedAddresses!.map((user) => {
									return `${user.address};${
										user.staked!.length
									}`;
								})
								.join("\n")}
							fileName={
								(
									pool.stakingTokens ?? [pool.stakingToken]
								).join("&") + "_stakers"
							}
						/>
                        */}

                        <hr />
                        <h3>
                            Deposit {pool.rewardToken} Rewards (
                            <FormatAmount
                                value={pool.rewardsAmount!.toString(10)}
                                token={pool.rewardToken!.split('-')[0]}
                                digits={5}
                                decimals={18}
                            />
                            &nbsp;already deposited - not distributed)
                        </h3>

                        <DepositRewards
                            scAddress={pool._address!}
                            token={
                                pool.rewardToken === utxToken.identifier
                                    ? utxToken
                                    : undefined
                            }
                            endpoint='deposit_rewards'
                        />
                    </div>
                ))}
                <div className='card p-4 mt-4'>
                    <button
                        className='btn btn-primary'
                        onClick={async () => {
                            await refreshAccount();
                            const { sessionId } = await sendTransactions({
                                transactions: {
                                    value: 0,
                                    data: 'sync',
                                    receiver:
                                        'erd1qqqqqqqqqqqqqpgqx2wq0kzxaduvh32ly8z558m5vcsfzfwrdn3q86qg9y',
                                    gasLimit: 600_000_000
                                },
                                transactionsDisplayInfo: {
                                    processingMessage: 'Syncing PeerMe',
                                    errorMessage:
                                        'An error has occured during sync',
                                    successMessage: 'PeerMe synced successfully'
                                }
                            });
                        }}
                    >
                        Sync PeerMe Contract
                    </button>
                </div>
            </div>
        </div>
    );
};

const DownloadButton = ({
    textOutput,
    fileName
}: {
    textOutput: string;
    fileName: string;
}) => {
    const file = new Blob([textOutput], { type: 'text/plain' });

    return (
        <a
            download={fileName + '.csv'}
            target='_blank'
            rel='noreferrer'
            href={URL.createObjectURL(file)}
            className='btn btn-primary'
        >
            Download Holders CSV
        </a>
    );
};
