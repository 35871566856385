import { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { Autoplay, EffectCards } from 'swiper/modules';

const CardSwiperOag = () => {
    const [oagImages, setOagImages] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        const importImages = async () => {
            const importedImages = await Promise.all([
                import('../assets/img/oag/7.webp'),
                import('../assets/img/oag/214.webp'),
                import('../assets/img/oag/252.webp'),
                import('../assets/img/oag/394.webp'),
                import('../assets/img/oag/2090.webp'),
                import('../assets/img/oag/2352.webp')
            ]);
            const imageUrls = importedImages.map((image) => image.default);
            setOagImages(imageUrls);
        };

        importImages();
    }, []);
    return (
        <Swiper
            loop={true}
            effect={'cards'}
            grabCursor={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false
            }}
            modules={[EffectCards, Autoplay]}
            spaceBetween={40}
            className='swiperCyborgs'
        >
            {oagImages.map((image, index) => (
                <SwiperSlide key={index}>
                    <img
                        src={image}
                        alt='cyborg'
                        className='h-100 object-fit-cover'
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CardSwiperOag;
