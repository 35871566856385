import React from 'react';
import FlipixLogo from 'assets/img/Flipix_logo.png';

interface BuyOnMarketplaceProps {
    stakingToken: string;
}

export const BuyOnMarketplace: React.FC<BuyOnMarketplaceProps> = ({
    stakingToken
}) => (
    <h6
        className='buy-on-marketplace-tag position-absolute top-0 m-0 p-2 pl-4 font-weight-bold bg-dark rounded'
        style={{ left: 0, top: 0, overflow: 'overlay', width: 'fit-content' }}
    >
        <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://flipix.io/collection/${stakingToken}`}
            className='text-white d-flex flex-row align-items-center'
        >
            Buy on
            <img height={16} src={FlipixLogo} alt='nftpool' className='ml-2' />
        </a>
    </h6>
);
