import { dAppName } from 'config';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';

import {
    Dashboard,
    Home,
    MysteryBox,
    AdminSettings,
    MysteryBoxResults,
    NftStaking,
    RewardsHistory,
    OAGStaking,
    CollectionsStaking,
    InfinityStaking,
    TokenStaking,
    RomaTicket,
    MembershipSwap
} from './pages';

export const routeNames = {
    home: '/',
    dashboard: '/dashboard',
    containers: '/containers',
    nftStaking: '/nft-staking',
    oagStaking: '/nft-staking/oag',
    collectionsStaking: '/nft-staking/collections',
    infinityStaking: '/nft-staking/infinity',
    tokenStaking: '/token-staking',
    mysteryBoxResults: '/mystery-box-results',
    romaTicket: '/roma-ticket',
    membershipSwap: '/membership-swap',
    adminSettings: '/admin-settings',
    unlock: '/unlock',
    rewardsHistory: '/rewards-history'
};

interface RouteWithTitleType extends RouteType {
    title: string;
}

export const routes: RouteWithTitleType[] = [
    {
        path: routeNames.home,
        title: 'Home',
        component: Home
    },
    {
        path: routeNames.dashboard,
        title: 'Dashboard',
        component: Dashboard,
        authenticatedRoute: true
    },
    {
        path: routeNames.containers,
        title: 'Mystery Box',
        component: MysteryBox,
        authenticatedRoute: true
    },
    {
        path: routeNames.nftStaking,
        title: 'NFT Staking',
        component: NftStaking,
        authenticatedRoute: true
    },
    {
        path: routeNames.oagStaking,
        title: 'OAG Staking',
        component: OAGStaking,
        authenticatedRoute: true
    },
    {
        path: routeNames.collectionsStaking,
        title: 'Collections Staking',
        component: CollectionsStaking,
        authenticatedRoute: true
    },
    {
        path: routeNames.infinityStaking,
        title: 'Infinity Staking',
        component: InfinityStaking,
        authenticatedRoute: true
    },
    {
        path: routeNames.tokenStaking,
        title: 'Token Staking',
        component: TokenStaking,
        authenticatedRoute: true
    },
    {
        path: routeNames.romaTicket,
        title: 'Roma Ticket',
        component: RomaTicket,
        authenticatedRoute: true
    },
    {
        path: routeNames.adminSettings,
        title: 'Admin Settings',
        component: AdminSettings,
        authenticatedRoute: true
    },
    {
        path: routeNames.membershipSwap,
        title: 'Membership Cards Swap',
        component: MembershipSwap,
        authenticatedRoute: true
    },
    {
        path: routeNames.mysteryBoxResults,
        title: 'Mystery Box Results',
        component: MysteryBoxResults
    },
    {
        path: routeNames.rewardsHistory,
        title: 'Rewards History',
        component: RewardsHistory
    }
];

export const mappedRoutes = routes.map((route) => {
    const title = route.title
        ? `${route.title} • MultiversX ${dAppName}`
        : `MultiversX ${dAppName}`;

    const requiresAuth = Boolean(route.authenticatedRoute);
    const wrappedComponent = withPageTitle(title, route.component);

    return {
        path: route.path,
        component: wrappedComponent,
        authenticatedRoute: requiresAuth
    };
});
