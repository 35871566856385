import React from 'react';
import { ReactComponent as HeartIcon } from '../../assets/img/heart.svg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTwitter,
    faTelegramPlane,
    faDiscord
} from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const Footer = () => {
    const banners = [
        {
            gif: 'svu.jpg',
            link: 'https://www.supervictornft.com/',
            alt: 'Super Victor'
        },
        {
            gif: 'evdefault.gif',
            link: 'https://discord.gg/evecosystem',
            alt: 'E.V Ecosystem'
        },
        {
            gif: 'sponsors.gif',
            link: 'https://discord.gg/evecosystem',
            alt: 'E.V Ecosystem'
        }
    ];

    const randomBanner =
        window.location.pathname === '/unlock'
            ? banners[0]
            : banners[Math.floor(Math.random() * banners.length)];

    return (
        <footer className='text-center mt-2 mb-3 '>
            <div className='d-flex flex-column align-items-center rounded-2xl mb-4'>
                <a
                    href={randomBanner.link}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex align-items-center'
                >
                    <img
                        src={'/banners/' + randomBanner.gif}
                        alt={randomBanner.alt}
                        style={{
                            width: '100%',
                            height: '150px',
                            borderRadius: '32px'
                        }}
                    />
                </a>
            </div>
            <div className='d-flex flex-column align-items-center'>
                <a
                    {...{
                        target: '_blank'
                    }}
                    className='d-flex align-items-center'
                    href='https://tortuga-staking.com/'
                >
                    Built by Tortuga Staking
                </a>
                <a
                    {...{
                        target: '_blank'
                    }}
                    className='mt-1 d-flex align-items-center'
                    href='https://www.eventivivi.it/nft'
                >
                    Delivered with <HeartIcon className='mx-1' /> from E.V
                    Ecosystem
                </a>
            </div>

            <div className='justify-center justify-items-center'>
                <a
                    href='https://x.com/EVNFTreal'
                    className='footer-link twitter transition-colors mr-4'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FontAwesomeIcon icon={faTwitter as IconProp} size='lg' />
                    <span className='ml-1'>Twitter</span>
                </a>

                <a
                    href='https://t.me/evnftreal'
                    className='footer-link telegram transition-colors mr-4'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FontAwesomeIcon
                        icon={faTelegramPlane as IconProp}
                        size='lg'
                    />
                    <span className='ml-1'>Telegram</span>
                </a>

                <a
                    href='https://discord.gg/evecosystem'
                    className='footer-link discord transition-colors'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <FontAwesomeIcon icon={faDiscord as IconProp} size='lg' />
                    <span className='ml-1'>Discord</span>
                </a>
            </div>
        </footer>
    );
};
