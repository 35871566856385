import { useEffect, useState } from 'react';
import { request } from 'graphql-request';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlNftStaking } from 'types';
import { NftStakingPoolComponent } from 'components/NftStakingPoolComponent';
import SwiperOag from 'components/swiperOag';
import Decimal from 'decimal.js';

export const OAGStaking = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();

    const [nftStakingPools, setNftStakingPools] = useState<
        gqlNftStaking[] | undefined
    >();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();
    const [errorLoading, setErrorLoading] = useState<boolean>(false);
    const [nStakedOag, setNStakedOag] = useState<number | undefined>();
    const [nStakedEvoag, setNStakedEvoag] = useState<number | undefined>();

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                  nftStakingPools {
                    _address
                    stakingToken
                    stakingTokens
                    rewardTokens
                    rewardsForUser(address:$user) {
                        tokenId
                        amount
                    }
                    userStaking(user:$user) {
                        token
                        nonce
                    }
                    userUnstaking(user:$user) {
                        token
                        nonce
                        unstake_deadline
                    }
                    unstakingPeriod
                    nStakedPositions
                  }
                  tokenPrice
                }
              }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.nftStakingPools) return;
                setNftStakingPools(
                    ev.nftStakingPools.filter((p) =>
                        [
                            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh',
                            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m',
                            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze',
                            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96',
                            'erd1qqqqqqqqqqqqqpgqsxu0745t995t9gxylvt9xls07dssvnjmdn3qn7sj4h',
                            'erd1qqqqqqqqqqqqqpgq88faj957nkqmwx9429ycsaa3cr88rkgjdn3qx4hrpz',
                            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5',
                            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
                        ].includes(p._address ?? '')
                    )
                );

                let nStakedOag =
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqyyfevpc7p9fjt3eaaumyz5cqxhvle0d4dn3qhaj2mh'
                    )[0].nStakedPositions ?? 0;
                nStakedOag +=
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqchw9422fu5m6205qggc8u28ddtdw8jxudn3qtl6pk5'
                    )[0].nStakedPositions ?? 0;
                nStakedOag +=
                    (ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze'
                    )[0].nStakedPositions ?? 0) / 2;
                nStakedOag +=
                    (ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
                    )[0].nStakedPositions ?? 0) / 3;
                setNStakedOag(nStakedOag);
                let nStakedEvoag =
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqjenyd0en0nrrgdat4jwpjsjr7szmf3lgdn3qjhuu4m'
                    )[0].nStakedPositions ?? 0;
                nStakedEvoag +=
                    ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqpxqgzyuyna7kmxh5hf9fmsur4m3lt37udn3qs3rhqg'
                    )[0].nStakedPositions ?? 0;
                nStakedEvoag +=
                    (ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqfmkxuwjazrgup0he2j2ryrkhld8pfu9ydn3qxxpgze'
                    )[0].nStakedPositions ?? 0) / 2;
                nStakedEvoag +=
                    (ev.nftStakingPools.filter(
                        (p) =>
                            p._address ===
                            'erd1qqqqqqqqqqqqqpgqe98h78xksheffcy2n3nwmdj5hfsmlvkxdn3qlttq96'
                    )[0].nStakedPositions ?? 0) / 3;
                setNStakedEvoag(nStakedEvoag);

                if (!ev.tokenPrice) return;
                setTokenPrice(new Decimal(ev.tokenPrice));
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    if (nftStakingPools === undefined) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        Odd Apes Gang
                    </h1>
                    <h1 className='text-center'>Loading...</h1>
                </div>
            </div>
        );
    }
    if (errorLoading) {
        return (
            <div className='container mt-5'>
                <div>
                    <h1 className='text-left text-custom ev-text'>
                        Odd Apes Gang
                    </h1>
                    <h1 className='text-center'>
                        Error loading data, please try again later
                    </h1>
                </div>
            </div>
        );
    }

    return (
        <div className='container-fluid w-100 d-flex flex-column flex-lg-row my-5'>
            <div className='dashboard-left w-100 col-lg-9'>
                <h1 className='text-left text-custom ev-text'>Odd Apes Gang</h1>
                <div className='staking-cards-container d-flex flex-row flex-wrap gap-5'>
                    {nftStakingPools.map((pool) => (
                        <NftStakingPoolComponent
                            pool={pool}
                            tokenPrice={tokenPrice}
                        />
                    ))}
                </div>
            </div>
            <div className='dashboard-right col-12 col-lg-3'>
                <div className='dashboard-right-container d-flex flex-column'>
                    <SwiperOag />
                    <div className='dashboard-right-info'>
                        <div className='p-3 text-center'>
                            <h3 className='font-weight-bold pb-3'>
                                Odd Apes Gang
                            </h3>
                            <div className='card-general-data card card-dashboard text-center relative overflow-visble'>
                                <div className='card-body'>
                                    <h4>Total supply: 3335</h4>
                                    {nStakedOag && (
                                        <h4>Staked: {nStakedOag}</h4>
                                    )}
                                    <h4>Royalties: 10%</h4>
                                </div>
                                <p className='text-danger'>
                                    Collection is sold out
                                </p>
                                <button
                                    className='btn btn-outline-primary btn-md active'
                                    onClick={() =>
                                        window.open(
                                            'https://flipix.io/collection/OAG-0eaf3b',
                                            '_blank'
                                        )
                                    }
                                >
                                    🛒 Trade now on FLIPiX
                                </button>
                            </div>

                            <h3 className='font-weight-bold py-3'>
                                Evolved Odd Apes Gang
                            </h3>
                            <div className='card-general-data card card-dashboard text-center relative overflow-visble'>
                                <div className='card-body'>
                                    <h4>Total supply: 3335</h4>
                                    {nStakedEvoag && (
                                        <h4>Staked: {nStakedEvoag}</h4>
                                    )}
                                    <h4>Royalties: 10%</h4>
                                </div>
                                <p className='text-danger'>
                                    Collection is sold out
                                </p>
                                <button
                                    className='btn btn-outline-primary btn-md active'
                                    onClick={() =>
                                        window.open(
                                            'https://flipix.io/collection/EVOAG-1a4f7d',
                                            '_blank'
                                        )
                                    }
                                >
                                    🛒 Trade now on FLIPiX
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
