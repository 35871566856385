import { useEffect, useState } from 'react';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import { NftVisualizer } from 'components/NftVisualizer';
import { PageState } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import {
    TokenPayment,
    TransferTransactionsFactory,
    GasEstimator,
    Address
} from '@multiversx/sdk-core';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import {
    controllerScAddress,
    cyborgIdentifier,
    membershipCardIdentifier
} from 'config';
import { faXmark, faGrip } from '@fortawesome/free-solid-svg-icons';
import { string2hex } from 'helpers';
import { NonFungibleToken } from 'types';

export const MembershipSwap = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const [transactionId, setTransactionId] = useState(null);
    const transactionStatus = useTrackTransactionStatus({ transactionId });

    const { address } = useGetAccount();

    const { success, fail } = useGetActiveTransactionsStatus();

    const [walletNfts, setWalletNfts] = useState<NonFungibleToken[]>([]);
    const [newNft, setNewNft] = useState<NonFungibleToken | null>(null);

    // Fetch wallet NFTs
    useEffect(() => {
        apiNetworkProvider
            .getAccountNftsFromCollection(address, membershipCardIdentifier)
            .then((res) => {
                setWalletNfts(
                    res.filter(
                        (nft) =>
                            !['Diamond', 'Gold', undefined].includes(
                                nft.metadata?.attributes?.find(
                                    (attr: any) => attr.trait_type === 'Color'
                                )?.value
                            )
                    )
                );
            });
    }, [success]);

    useEffect(() => {
        if (
            //success &&
            transactionStatus !== undefined &&
            transactionStatus.transactions !== undefined
        ) {
            apiNetworkProvider
                .getTransaction(transactionStatus.transactions[0].hash)
                .then((tx) => {
                    const scResult = tx.contractResults.items.find((item) =>
                        item.data.startsWith('@6f6b@')
                    )!;
                    const nonce = scResult.data.split('@')[2];

                    apiNetworkProvider
                        .getNftWithIdentifier(`${cyborgIdentifier}-${nonce}`)
                        .then((newNft) => {
                            setNewNft(newNft);
                        });
                });
        }
    }, [success]);

    const changeChecked = (index: number, checked: boolean) => {
        const _walletNfts = [...walletNfts];
        _walletNfts[index]._checked = checked;
        setWalletNfts(_walletNfts);
    };

    return (
        <div>
            {newNft && (
                <div
                    className='position-fixed top-0 start-0 d-flex'
                    style={{
                        zIndex: 1000,
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        height: '100vh',
                        width: '100vw',
                        top: '0',
                        left: '0',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <FontAwesomeIcon
                        icon={faXmark}
                        className='fa-2xl mb-4'
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => setNewNft(null)}
                    />
                    <h2>Your newly minted Cyborg</h2>
                    <img src={newNft.media[0].url} className='h-50 mt-2' />
                </div>
            )}

            <div className='container mt-5'>
                <h1 className='text-center text-custom ev-text mb-5'>
                    Swap 10 Membership Cards for 1 E.V Cyborg NFT
                </h1>

                <div className='card'>
                    <button
                        className='btn btn-primary btn-lg w-75 my-3 mx-auto'
                        disabled={
                            walletNfts.filter((nft) => nft._checked).length !==
                            10
                        }
                        onClick={async () => {
                            const nftsToSwap = walletNfts.filter(
                                (nft) => nft._checked
                            );

                            const payload =
                                new TransferTransactionsFactory(
                                    new GasEstimator()
                                )
                                    .createMultiESDTNFTTransfer({
                                        tokenTransfers: nftsToSwap.map((nft) =>
                                            TokenPayment.nonFungible(
                                                nft.collection,
                                                nft.nonce
                                            )
                                        ),
                                        destination: new Address(
                                            controllerScAddress
                                        ),
                                        sender: new Address(address),
                                        chainID: '1'
                                    })
                                    .getData()
                                    .toString() +
                                '@' +
                                string2hex('claimCyborgFromCards');

                            await refreshAccount();

                            const { sessionId } = await sendTransactions({
                                transactions: {
                                    value: 0,
                                    data: payload,
                                    receiver: address,
                                    gasLimit: 30_000_000
                                },
                                transactionsDisplayInfo: {
                                    processingMessage:
                                        'Swapping Membership Cards',
                                    errorMessage: 'Error during swap',
                                    successMessage: 'Cards swapped successfully'
                                }
                            });
                            setTransactionId(sessionId);
                        }}
                    >
                        {walletNfts.filter((nft) => nft._checked).length !== 10
                            ? 'Select 10 cards to swap'
                            : 'Swap'}
                    </button>
                    <p className='p-2 text-center'>
                        You can only swap common cards on the dApp. To swap
                        other cards, please open a ticket on Discord.
                    </p>

                    <div className='nft-container pb-2'>
                        {walletNfts.map((nft, i) => (
                            <NftVisualizer
                                nft={nft}
                                contract={''}
                                changeCallback={(checked: boolean) =>
                                    changeChecked(i, checked)
                                }
                            />
                        ))}
                        {walletNfts.length === 0 && (
                            <PageState
                                icon={faGrip}
                                title="You don't have any common Membership Card in your wallet"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
