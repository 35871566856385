import React, { useEffect, useState, useMemo } from 'react';
import { routeNames } from 'routes';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { refreshAccount } from '@multiversx/sdk-dapp/utils/account/refreshAccount';
import { useTrackTransactionStatus } from '@multiversx/sdk-dapp/hooks';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import {
    TokenPayment,
    TransferTransactionsFactory,
    GasEstimator,
    Address,
    AbiRegistry,
    SmartContract,
    ResultsParser
} from '@multiversx/sdk-core';
import { FormatAmount } from '@multiversx/sdk-dapp/UI';
import {
    MyApiNetworkProvider,
    TransactionOnNetwork
} from 'helpers/MyApiNetworkProvider';
import {
    mBoxIdentifier,
    mBoxScAddress,
    romaScAddress,
    romaTicketIdentifier
} from 'config';
import { string2hex, hex2string } from 'helpers';
import { NonFungibleToken } from 'types';
import { BigNumber } from 'bignumber.js';
import MbGold from 'assets/img/mb-gold.png';
import MbSilver from 'assets/img/mb-silver.png';
import MbBronze from 'assets/img/mb-bronze.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import RomaAbi from 'roma.abi.json';

export const RomaTicket = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const [transactionId, setTransactionId] = useState<string>('');

    const { address } = useGetAccount();
    const { success, fail } = useGetActiveTransactionsStatus();
    const transactionStatus = useTrackTransactionStatus({ transactionId });

    const [winner, setWinner] = useState<Number | undefined>();
    const [winners, setWinners] = useState<Number[] | undefined>();
    const [userNfts, setUserNfts] = useState<NonFungibleToken[]>([]);

    const fetchWinners = async () => {
        const contract = new SmartContract({
            address: new Address(romaScAddress),
            abi: AbiRegistry.create(RomaAbi)
        });

        const interaction = contract.methods.getWinners();
        const query = interaction.check().buildQuery();
        const networkProvider = new ProxyNetworkProvider(apiAddress);
        const queryResponse = await networkProvider.queryContract(query);
        const typedBundle = new ResultsParser().parseQueryResponse(
            queryResponse,
            interaction.getEndpoint()
        );
        if (typedBundle.firstValue) {
            const winners = typedBundle.firstValue.valueOf();
            setWinners(winners.map((w: any) => w.toNumber()));
        }
    };

    const fetchWinner = async () => {
        const contract = new SmartContract({
            address: new Address(romaScAddress),
            abi: AbiRegistry.create(RomaAbi)
        });

        const interaction = contract.methods.getWinnerBigPrize();
        const query = interaction.check().buildQuery();
        const networkProvider = new ProxyNetworkProvider(apiAddress);
        const queryResponse = await networkProvider.queryContract(query);
        const typedBundle = new ResultsParser().parseQueryResponse(
            queryResponse,
            interaction.getEndpoint()
        );
        if (typedBundle.firstValue) {
            const winner = typedBundle.firstValue.valueOf().toNumber();
            setWinner(winner);
        }
    };

    const fetchNfts = async () => {
        apiNetworkProvider
            .getAccountNftsFromCollection(address, romaTicketIdentifier)
            .then((nfts) => {
                setUserNfts(nfts);
            })
            .catch((error) => console.error(error));
    };

    const fetchData = async () => {
        fetchWinners();
        fetchWinner();
        fetchNfts();
    };

    useEffect(() => {
        fetchData();
    }, [success]);

    const winningNfts = useMemo(() => {
        return {
            bigWinner: userNfts.find((nft) => nft.nonce === winner),
            winning: userNfts.filter((nft) => winners?.includes(nft.nonce)),
            nonWinning: userNfts.filter((nft) => !winners?.includes(nft.nonce))
        };
    }, [winners, winner, userNfts]);

    return (
        <div>
            <div className='roma-ticket-container container mt-5'>
                <h1 className='text-center text-custom ev-text mt-5'>
                    The first edition of Roma Tickets is over! <br />
                    Stay tuned for the next one!
                </h1>
                <h1 className='text-center text-custom ev-text mt-5 mb-5'>
                    Coming soon!
                </h1>
            </div>
        </div>
    );

    /*return (
        <div>
            <div className='roma-ticket-container container mt-5'>
                <h1 className='text-center text-custom ev-text mb-5'>
                    The first edition of Roma Tickets is over! <br />
                    Stay tuned for the next one!
                </h1>

                {/*
				<h1 className="text-center text-custom ev-text mb-5">
					You can claim your prizes for the Roma Tickets in your
					wallet👇
				</h1>

				{winningNfts.bigWinner && (
					<div className="row justify-content-evenly gap-30 text-center mb-3">
						<div className="col-md card card-dashboard">
							<h2 className="mb-4 mt-3 ev-text">
								You have the winning ticket for the Roma Trip!
								Congratulations!
							</h2>
						</div>
					</div>
				)}

				<div className="row justify-content-evenly gap-30 text-center mb-5">
					<div className="col-md card card-dashboard">
						<h2 className="mb-4 mt-3">
							<FormatAmount
								value={new BigNumber("250000000000000000")
									.multipliedBy(winningNfts.winning.length)
									.toString(10)}
								token={"EGLD"}
								digits={2}
								decimals={18}
							/>
						</h2>
					</div>
					<div className="col-md card card-dashboard">
						<h2 className="mb-4 mt-3">
							<FormatAmount
								value={new BigNumber("10000000000000000000")
									.multipliedBy(winningNfts.nonWinning.length)
									.toString(10)}
								token={"UTX"}
								digits={0}
								decimals={18}
							/>
						</h2>
					</div>
				</div>
    }
                <div className='text-center mt-3'>
                    <button
                        className='btn btn-primary btn-lg w-75 mb-3 mx-auto'
                        disabled
                        onClick={async () => {
                            const payload =
                                new TransferTransactionsFactory(
                                    new GasEstimator()
                                )
                                    .createMultiESDTNFTTransfer({
                                        tokenTransfers: userNfts
                                            .filter(
                                                (nft) => nft.nonce !== winner
                                            )
                                            .map((nft) =>
                                                TokenPayment.nonFungible(
                                                    nft.collection,
                                                    nft.nonce
                                                )
                                            ),
                                        destination: new Address(romaScAddress),
                                        sender: new Address(address),
                                        chainID: '1'
                                    })
                                    .getData()
                                    .toString() +
                                '@' +
                                string2hex('claim_prizes');

                            await refreshAccount();

                            const { sessionId } = await sendTransactions({
                                transactions: {
                                    value: 0,
                                    data: payload,
                                    receiver: address,
                                    gasLimit: 120_000_000
                                },
                                transactionsDisplayInfo: {
                                    processingMessage:
                                        'Claiming Roma Tickets Prizes',
                                    errorMessage:
                                        'Error claiming Roma Tickets Prizes',
                                    successMessage:
                                        'Roma Tickets Prizes claimed'
                                }
                            });
                        }}
                    >
                        Claim Prizes
                    </button>
                </div>

                <h1 className='mb-5 mt-5 text-center text-custom ev-text'>
                    Lottery results:
                </h1>

                {winner && (
                    <div className='text-center'>
                        <h2 className='mb-3'>Roma Trip Winning Ticket</h2>
                        <div className='row justify-content-evenly card-container'>
                            <div className='col-custom-5 col-md-4 col-sm-6 col-12 mb-3 d-flex justify-content-center '>
                                <div className='card card-dashboard mb-2 card-prize flex-fill'>
                                    <div className='card-body'>
                                        <h3 className='card-title'>
                                            # {winner.toString()}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {winners && winners.length > 0 && (
                    <div className='text-center'>
                        <h2 className='mb-3'>
                            EGLD Winning Tickets (0.25 EGLD each)
                        </h2>
                        <div className='row justify-content-evenly  card-container'>
                            {winners.map((w, i) => (
                                <div className='col-custom-5 col-md-4 col-sm-6 col-12 mb-3 d-flex justify-content-center '>
                                    <div className='card card-dashboard mb-2 card-prize flex-fill'>
                                        <div className='card-body'>
                                            <h3 className='card-title'>
                                                # {w.toString()}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );*/
};
