import React from 'react';

export const DashboardLink = ({
    title,
    subtitle,
    img,
    buttonText,
    buttonDisabled,
    onClick
}: {
    title: string;
    subtitle: string;
    img: string;
    buttonText: string;
    buttonDisabled?: boolean;
    onClick?: () => void;
}) => {
    return (
        <div className='single-staking-card w-100 w-md-50 d-flex justify-content-center gap-4 '>
            <div
                className='card card-dashboard text-center relative'
                onClick={onClick}
            >
                <h2 className='mb-4 mt-3'>{title}</h2>
                <h5 className='mb-5'>{subtitle}</h5>
                <img src={img} alt='mb-gold' className='mb-5 w-100 maxh-170' />
            </div>
        </div>
    );
};
