import React, { memo, useEffect, useRef } from 'react';

function TradingViewWidget() {
    const container = useRef<any>();

    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML = `
        {
          "autosize": true,
          "symbol": "XEXCHANGE:UNITYXUSDC",
          "interval": "30",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "enable_publishing": false,
          "allow_symbol_change": false,
          "hide_top_toolbar": true,
          "hide_legend": true,
          "save_image": false,
          "calendar": false,
          "hide_volume": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
        container.current?.appendChild(script);
    }, []);

    return (
        <div className='tradingview-widget-container' ref={container}>
            <div
                className='tradingview-widget-container__widget'
                style={{ height: 'calc(100% - 32px)', width: '100%' }}
            ></div>
        </div>
    );
}

export default memo(TradingViewWidget);
