import BigNumber from 'bignumber.js';
export * from './sdkDappHelpers';

export const string2hex = (tmp: string): string => {
    var str = '';
    for (var i = 0; i < tmp.length; i++) {
        str += tmp[i].charCodeAt(0).toString(16);
    }
    return str;
};

export function hex2string(hexx: string): string {
    var hex = hexx.toString(); //force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

export const number2hex = (tmp: number): string => {
    var str = tmp.toString(16);
    if (str.length % 2 === 1) {
        str = '0' + str;
    }
    return str;
};

export const bignumber2hex = (tmp: BigNumber): string => {
    var str = tmp.toString(16);
    if (str.length % 2 === 1) {
        str = '0' + str;
    }
    return str;
};

export const timestampToDateTime = (timestamp: number | undefined) => {
    if (timestamp === undefined || isNaN(timestamp)) {
        return 'N/A';
    }
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

export const timestampToDate = (timestamp: number | undefined) => {
    if (timestamp === undefined || isNaN(timestamp)) {
        return 'N/A';
    }
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString();
};
