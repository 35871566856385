import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';

const HomePage = () => {
    return (
        <div className='d-flex flex-fill align-items-center container'>
            <div className=' w-100'>
                <div className='col-12 col-md-8 col-lg-5 mx-auto'>
                    <div className='card shadow-sm p-4 border-0 bg-dock'>
                        <div className='card-body d-flex flex-column justify-content-around align-items-center'>
                            <h2 className='text-center mt-3 mb-4'>
                                <span className='text-custom font-weight-bold ev-text mb-3'>
                                    E.V Ecosystem dApp
                                </span>
                            </h2>
                            <p className='mb-3'>
                                Login using your MultiversX wallet
                            </p>

                            <Link
                                to={routeNames.unlock}
                                className='btn btn-primary btn-lg mt-3 btn-custom'
                            >
                                LOGIN
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Home = () => (
    <AuthRedirectWrapper>
        <HomePage />
    </AuthRedirectWrapper>
);
