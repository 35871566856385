import { InternalToken } from 'types';

export const dAppName = 'dApp';

export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';

export const graphqlUrl = 'https://dapps-backend.dbcodingsrl.com/graphql';

export const utxIdentifier = 'UNITYX-3181a2';
export const lkutxIdentifier = 'LKUNITYX-f0ace3';
export const utxDecimals = 18;
export const mBoxIdentifier = 'OAGMYSTERY-f98294';
export const mBoxScAddress =
    'erd1qqqqqqqqqqqqqpgqxpkagqaqp9xz0cq5cq9a9fjtxde7d5aydn3qvnvqls';
export const romaScAddress =
    'erd1qqqqqqqqqqqqqpgqh42hfjrpns5zk9mdmhqly2r32pnjrttsdn3qduwgtp';
export const controllerScAddress =
    'erd1qqqqqqqqqqqqqpgqx2wq0kzxaduvh32ly8z558m5vcsfzfwrdn3q86qg9y';
export const romaTicketIdentifier = 'ROMATKT-6812c0';
export const membershipCardIdentifier = 'MEMBCARD-b7e2b9';
export const cyborgIdentifier = 'EVCYB-aea8b4';

export const utxToken: InternalToken = {
    name: 'UNITYX',
    symbol: 'UTX',
    identifier: 'UNITYX-3181a2',
    decimals: 18,
    decimalsToDisplay: 2
};

export const stakingToken: InternalToken = {
    name: 'CucumberX',
    symbol: 'CUMB',
    identifier: 'XCUMB-da0e35',
    decimals: 18,
    decimalsToDisplay: 2
};

export const rewardToken: InternalToken = {
    name: 'CucumberX',
    symbol: 'CUMB',
    identifier: 'XCUMB-da0e35',
    decimals: 18,
    decimalsToDisplay: 4
};

/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];

export const adminAddresses = [
    'erd19hcnc2djsjay3prvhuzr0phveducv93khj435pqjza73tcyu4jwsuqywdh',
    'erd1g3dygd6kh2nt5kvkl6fpp3rp3y9ez5wfaxwwfzzkglwqn2aydn3q6vqkxk',
    'erd189zma5nwfem4dj0l6mdsv67fgjr633q0pxqqvyzpt52aqkva6dmsxymqew'
];
