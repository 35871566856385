import { useEffect, useState } from 'react';
import { request } from 'graphql-request';
import {
    useGetAccount,
    useGetActiveTransactionsStatus,
    useGetNetworkConfig
} from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { graphqlUrl } from 'config';
import { Query, gqlTokenStaking } from 'types';
import Decimal from 'decimal.js';
import { TokenStakingPoolComponent } from 'components/TokenStakingPoolComponent';

export const TokenStaking = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const { address } = useGetAccount();

    const { success, fail } = useGetActiveTransactionsStatus();

    const [tokenStakingPools, setTokenStakingPools] = useState<
        gqlTokenStaking[] | undefined
    >();
    const [tokenPrice, setTokenPrice] = useState<Decimal | undefined>();
    const [errorLoading, setErrorLoading] = useState<boolean>(false);

    const fetchData = () => {
        request<Query>(
            graphqlUrl,
            `
            query($user: String) {
                ev {
                    tokenStakingPools {
                        _address
                        rewardsForUser(address: $user)
                        admin
                        stakingToken
                        membershipToken
                        rewardToken
                        rewardsAmount
                        userStaking(user:$user) {
                            staked_amount
                            staked_epoch
                            last_claimed_timestamp
                        }
                        currentUserBoost(user: $user) {
                            card_type
                            boost_begin
                            boost_deadline
                        }
                        userUnstaking(user: $user) {
                            id
                            amount
                            unstake_begin
                            unstake_deadline
                        }
                        userAutoRestakeDeadline(user: $user)
                        unstakingPeriod
                        fastUnstakingPenalty
                    }
                    tokenPrice
                } 
           }
            `,
            {
                user: address
            }
        )
            .then(({ ev }) => {
                if (!ev) return;
                if (!ev.tokenStakingPools) return;
                setTokenStakingPools(ev.tokenStakingPools);
                if (!ev.tokenPrice) return;
                setTokenPrice(new Decimal(ev.tokenPrice));
            })
            .catch((err: any) => {
                console.error(err);
                setErrorLoading(true);
            });
    };

    useEffect(() => {
        fetchData();
    }, [success, fail]);

    useEffect(() => {
        fetchData();
        const interval = setInterval(function () {
            fetchData();
        }, 6000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    if (tokenStakingPools === undefined) {
        return (
            <div className='container-token-staking'>
                <div className='container mt-5'>
                    <div>
                        <h1 className='text-center mb-5 text-custom ev-text'>
                            UTX Staking
                        </h1>
                        <h1 className='text-center'>Loading...</h1>
                    </div>
                </div>
            </div>
        );
    }

    if (errorLoading) {
        return (
            <div className='container-token-staking'>
                <div className='container mt-5'>
                    <div>
                        <h1 className='text-center mb-5 text-custom ev-text'>
                            UTX Staking
                        </h1>
                        <h1 className='text-center'>
                            Error loading data, please try again later
                        </h1>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='container-token-staking'>
            <div className='container my-auto'>
                <div className='container mt-5'>
                    <div>
                        <h1 className='text-center mb-5 text-custom ev-text'>
                            UTX Staking
                        </h1>
                        {tokenStakingPools.map((pool) => (
                            <TokenStakingPoolComponent
                                pool={pool}
                                tokenPrice={tokenPrice}
                                key={pool._address}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
