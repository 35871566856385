import { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { Autoplay, EffectCards } from 'swiper/modules';

const CardSwiperAllCollections = () => {
    const [oagImages, setOagImages] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        const importImages = async () => {
            const importedImages = await Promise.all([
                // import("../assets/img/Banner-RP-Mint.jpg"),
                // import("../assets/img/HeadOwl.jpg"),
                // import("../assets/img/JoXer.jpg"),
                // import("../assets/img/Multifights.png"),
                // import("../assets/img/interaza.png"),
                // import("../assets/img/pingu.jpg"),
                // import("../assets/img/hodl.jpg"),
                // import("../assets/img/opx.png"),
                import('../assets/img/oag/7.webp'),
                import('../assets/img/cyborgs/34.webp'),
                import('../assets/img/membership/36.webp'),
                import('../assets/img/oag/214.webp'),
                import('../assets/img/cyborgs/422.webp'),
                import('../assets/img/membership/119.webp'),
                import('../assets/img/oag/252.webp'),
                import('../assets/img/cyborgs/512.webp'),
                import('../assets/img/membership/207.webp'),
                import('../assets/img/oag/394.webp'),
                import('../assets/img/cyborgs/3884.webp'),
                import('../assets/img/membership/430.webp'),
                import('../assets/img/oag/2090.webp'),
                import('../assets/img/cyborgs/5144.webp'),
                import('../assets/img/membership/815.webp'),
                import('../assets/img/oag/2352.webp'),
                import('../assets/img/cyborgs/6004.webp'),
                import('../assets/img/membership/1888.webp'),
                import('../assets/img/membership/2182.webp'),
                import('../assets/img/membership/4808.webp'),
                import('../assets/img/cyborgs/6144.webp')
            ]);
            const imageUrls = importedImages.map((image) => image.default);
            setOagImages(imageUrls);
        };

        importImages();
    }, []);
    return (
        <Swiper
            loop={true}
            effect={'cards'}
            grabCursor={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false
            }}
            modules={[EffectCards, Autoplay]}
            spaceBetween={40}
            className='swiperCyborgs'
        >
            {oagImages.map((image, index) => (
                <SwiperSlide key={index}>
                    <img
                        src={image}
                        alt='cyborg'
                        className='h-100 object-fit-cover'
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CardSwiperAllCollections;
