import React from 'react';

type SectionSelectorProps = {
    section: string;
    sections: string[];
    sectionCounts: { [key: string]: number };
    setSection: any; //TODO cercare di mettere Dispatch<SetStateAction<Section>>
    className?: string;
};

export const SectionSelector = ({
    section,
    sections,
    sectionCounts,
    setSection,
    className
}: SectionSelectorProps) => {
    return (
        <div
            className={className + ' btn-group btn-group-toggle'}
            data-toggle='buttons'
        >
            {sections.map((s: string, i: number) => (
                <label
                    className={
                        'btn btn-outline-primary btn-md ' +
                        (section == s ? 'active' : 'section-toggler-custom')
                    }
                    key={i}
                >
                    <input
                        type='radio'
                        name='section'
                        {...(section == s ? { checked: true } : {})}
                        onClick={() => setSection(s)}
                    />
                    {s} ({sectionCounts[s]})
                </label>
            ))}
        </div>
    );
};
