import { useEffect, useState } from 'react';
import { useGetNetworkConfig } from 'hooks';
import { MyApiNetworkProvider } from 'helpers/MyApiNetworkProvider';
import { hex2string } from 'helpers';
import { BigNumber } from 'bignumber.js';

enum Step {
    start,
    opening,
    opened
}

enum PrizeType {
    NonWinning = 1,
    Nft = 2,
    PartnerNft = 3,
    Egld = 4,
    Discount = 5,
    RomaTicket = 6,
    Utx = 7
}

type Prize = {
    id: number;
    type: PrizeType;
    token: string;
    amount: BigNumber;
    nonce: number;
};

export const MysteryBoxResults = () => {
    const {
        network: { apiAddress }
    } = useGetNetworkConfig();
    const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

    const [prizes, setPrizes] = useState<any[]>([]);

    const fetchData = () => {
        fetch(
            'https://api.multiversx.com/accounts/erd1qqqqqqqqqqqqqpgqse29lk0272qq35vemv3tmk3rv0l4r756dn3q5z443e/results?size=10000'
        )
            .then((res) => res.json())
            .then((result) => {
                let usersPrizes: any[] = [];

                result.forEach((item: any) => {
                    try {
                        const decodedData = atob(item.data);
                        if (decodedData.startsWith('@6f6b@')) {
                            const components = decodedData.split('@').slice(2);

                            for (let i = 0; i < components.length; i += 5) {
                                let [
                                    prizeId,
                                    prizeType,
                                    prizeToken,
                                    prizeAmount,
                                    prizeNonce
                                ] = components.slice(i, i + 5);

                                usersPrizes.push({
                                    id: parseInt(prizeId, 16),
                                    type: parseInt(prizeType, 16) as PrizeType,
                                    token: hex2string(prizeToken),
                                    amount: new BigNumber(prizeAmount, 16),
                                    nonce: prizeNonce
                                        ? parseInt(prizeNonce, 16)
                                        : 0,
                                    user: item.receiver
                                });
                            }
                        }
                    } catch (error) {}
                });

                setPrizes(usersPrizes);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className='container mt-5'>
                <table className='table table-dark'>
                    <thead>
                        <tr>
                            <th scope='col'>User</th>
                            <th scope='col'>Prize</th>
                            <th scope='col'>Token</th>
                            <th scope='col'>Amount</th>
                            <th scope='col'>Nonce</th>
                        </tr>
                    </thead>
                    <tbody>
                        {prizes.map((prize) => (
                            <tr key={prize.id}>
                                <td>{prize.user}</td>
                                <td>
                                    {prize.type == PrizeType.Nft
                                        ? 'NFT'
                                        : prize.type == PrizeType.PartnerNft
                                          ? 'Partner NFT'
                                          : prize.type == PrizeType.Egld
                                            ? 'EGLD'
                                            : prize.type == PrizeType.Discount
                                              ? 'Discount'
                                              : prize.type ==
                                                  PrizeType.RomaTicket
                                                ? 'Roma Ticket'
                                                : prize.type == PrizeType.Utx
                                                  ? 'UTX'
                                                  : 'Non Winning'}
                                </td>
                                <td>
                                    {prize.type == PrizeType.Discount
                                        ? ''
                                        : prize.token}
                                </td>
                                <td>
                                    {prize.amount.gt(100)
                                        ? prize.amount
                                              .div(10 ** 18)
                                              .toString(10)
                                        : prize.amount.toString(10)}
                                </td>
                                <td>{prize.nonce == 0 ? '' : prize.nonce}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
