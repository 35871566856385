import React from 'react';
import {
    AuthRedirectWrapper,
    ExtensionLoginButton,
    LedgerLoginButton,
    OperaWalletLoginButton,
    WalletConnectLoginButton,
    WebWalletLoginButton,
    XaliasLoginButton
} from 'components';
import { routeNames } from 'routes';

const UnlockPage = () => {
    const commonProps = {
        callbackRoute: routeNames.dashboard,
        nativeAuth: true
    };

    return (
        <div className='home d-flex flex-fill align-items-center justify-content-center mx-4'>
            <div className='login-card' data-testid='unlockPage'>
                <div className='card my-4 text-center'>
                    <div className='card-body d-flex justify-content-around flex-column'>
                        <h1 className=' mb-4'>Login</h1>
                        <h5 className='mb-4'>Pick a login method</h5>
                        <div className='d-flex justify-content-around flex-column '>
                            <ExtensionLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                            <OperaWalletLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                            <WebWalletLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                            <LedgerLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                            <WalletConnectLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                            <XaliasLoginButton
                                {...commonProps}
                                buttonClassName='btn-unlock'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Unlock = () => (
    <AuthRedirectWrapper>
        <UnlockPage />
    </AuthRedirectWrapper>
);
