import { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

// import required modules
import { EffectCards, Autoplay } from 'swiper/modules';

const CardSwiperMembership = () => {
    const [cyborgImages, setCyborgImages] = useState<(string | undefined)[]>(
        []
    );

    useEffect(() => {
        const importImages = async () => {
            const importedImages = await Promise.all([
                import('../assets/img/membership/36.webp'),
                import('../assets/img/membership/119.webp'),
                import('../assets/img/membership/207.webp'),
                import('../assets/img/membership/430.webp'),
                import('../assets/img/membership/815.webp'),
                import('../assets/img/membership/1888.webp'),
                import('../assets/img/membership/2182.webp'),
                import('../assets/img/membership/4808.webp')
            ]);

            const imageUrls = importedImages.map((image) => image.default);
            setCyborgImages(imageUrls);
        };

        importImages();
    }, []);
    return (
        <Swiper
            effect={'cards'}
            grabCursor={true}
            spaceBetween={40}
            modules={[EffectCards, Autoplay]}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false
            }}
            loop={true}
            className='swiperMembership'
        >
            {cyborgImages.map((image, index) => (
                <SwiperSlide key={index}>
                    {' '}
                    <img
                        src={image}
                        alt='membershipcard'
                        className='h-100 object-fit-cover'
                    />{' '}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CardSwiperMembership;
